<template>
  <div class="payzilla">
    <!-- 底部 -->
    <div class="bottom">
      <div class="bottom-btm">
        <div class="btm-top">
          <div class="btm-top-left">
            <img src="../assets/image/logo.png" alt="" />
          </div>
          <div v-if="width == 2" class="right-content">
            <div class="h5-none">Socials</div>
            <div class="right-content-icon">
              <img src="../assets/image/icon7.png" alt="" />
              <img src="../assets/image/icon8.png" alt="" />
              <img v-if="width == 1" src="../assets/image/icon9.png" alt="" />
              <img v-else src="../assets/image/icon16.png" alt="" />
              <img src="../assets/image/icon10.png" alt="" />
            </div>
          </div>
          <div class="right-content">
            <div>Products</div>
            <div class="content-flex">
              <!-- <div @click="scrollToPosition">USD</div> -->
              <!-- <div @click="scrollToPosition">EUR</div> -->
              <div @click="scrollToPosition">Buy/sell USDC</div>
              <div @click="scrollToPosition">Buy/sell USDT</div>
              <div @click="handleAccounts">Peronal Account</div>
              <div @click="handleAccounts">Business Account</div>
              <!-- <div>Payout API</div>
                <div>Swap</div>
                <div>OTC</div> -->
            </div>
          </div>
          <div class="right-content">
            <div>Company</div>
            <div class="content-flex">
              <div @click="handleAbout">About</div>
              <div
                @click="
                  handlePDF(
                    'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Terms-of-service_latest.pdf'
                  )
                "
              >
                Terms of service
              </div>
              <div
                @click="
                  handlePDF(
                    'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Terms-of-service_latest.pdf'
                  )
                "
              >
                Privacy Statement
              </div>
              <div
                @click="
                  handlePDF(
                    'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Cookie-Policy_latest.pdf'
                  )
                "
              >
                Cookie Policy
              </div>
              <div
                @click="
                  handlePDF(
                    'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-AML_KYC-Policy_latest.pdf'
                  )
                "
              >
                AML/KYC Policy
              </div>
              <!-- <div>Developers</div>
                <div>Support</div>
                <div>Contact Sales</div>
                <div @click="
                  handlePDF(
                    'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Terms-of-service_latest.pdf'
                  )
                  ">
                  Terms of service
                </div> -->
            </div>
          </div>
          <div class="right-content">
            <div>Support</div>
            <div class="content-flex">
              <div class="h5-none">Log in</div>
              <div class="h5-none">Sign up</div>
              <div @click="handleContact">Contact us</div>
              <div @click="handleRamps">On & Off Ramps</div>
            </div>
          </div>
          <div v-if="width == 1" class="right-content">
            <div class="h5-none">Socials</div>
            <div class="right-content-icon">
              <img src="../assets/image/icon7.png" alt="" />
              <img src="../assets/image/icon8.png" alt="" />
              <img src="../assets/image/icon9.png" alt="" />
              <img src="../assets/image/icon10.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="fw-400 mt-200">
        <span>
          Payzilla is the brand name of the crypto exchange gateway owned by
          Liechtenstein-based Payzilla International AG and provides on and off
          ramping service to individual customers, merchant customers and web3
          platforms. Payzilla’s service is registered as TT Exchange Service
          Provider under TVTG by the FMA Liechtenstein with Registration no.
          342210.
        </span>
      </div>
      <div class="btm-font-text mt-25">
        Registered business address: Alvierweg 17, 9490 Vaduz, Liechtenstein
      </div>
      <div class="btm-font-text mt-100 mt-25">
        Copyright © 2024 Payzilla International AG
      </div>
    </div>
    <el-dialog
      class="dialog-web"
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span class="dialog-text"
        >drop us an enquiry email to: support@payzilla.io</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          class="dialog-btn"
          type="primary"
          @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹框 -->
    <div class="bottom-dialog" v-if="dialogVisibleH5">
      <div class="dialog-top">
        <div>Tips</div>
        <div @click="dialogVisibleH5 = false">X</div>
      </div>
      <div class="dialog-center">
        drop us an enquiry email to: support@payzilla.io
      </div>
      <div class="dialog-bottom-btn">
        <div @click="dialogVisibleH5 = false" class="btn">Confirm</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "index",
  data() {
    return {
      width: 2, // 1 web 2 H5
      dialogVisible: false,
      dialogVisibleH5: false,
    };
  },
  created() {},
  mounted() {
    //获取页面宽度
    let userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (
      userAgent.indexOf("mobile") !== -1 ||
      userAgent.indexOf("android") !== -1 ||
      userAgent.indexOf("iphone") !== -1 ||
      userAgent.indexOf("ipad") !== -1 ||
      userAgent.indexOf("windows phone") !== -1
    ) {
      this.width = 2;
    }
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
  },
  methods: {
    handleType() {
      this.showIcon = true;
      this.showMenu = false;
      this.showSupportIcon = true;
    },
    handleAbout() {
      if (this.$router.path != "/about") {
        this.handleType();
        this.$router.push({
          path: "/about",
        });
      }
    },
    handleAccounts() {
      if (this.$router.path != "/index") {
        this.handleType();
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".card");
          if (this.width == 1) scrollPosition = scrollPosition + 80;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".card");
        if (this.width == 1) scrollPosition = scrollPosition + 80;
        this.handleScrollTo(scrollPosition);
      }
    },
    //滚动到指定位置
    scrollToPosition() {
      if (this.$router.path != "/index") {
        this.handleType();
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".content");
          scrollPosition = scrollPosition - 80;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".content");
        scrollPosition = scrollPosition - 80;
        this.handleScrollTo(scrollPosition);
      }
    },
    // 动态计算滚动位置
    calculateScrollPosition(className) {
      let contentElement = document.querySelector(className);
      let contentTop =
        contentElement.getBoundingClientRect().top + window.pageYOffset;
      // 根据屏幕宽度调整滚动位置
      let scrollPosition = contentTop;
      return scrollPosition;
    },
    // 页面滚动
    handleScrollTo(position) {
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    //监听页面宽度变化
    checkMediaQuery() {
      if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
        this.handleType();
        this.width = 2;
      } else {
        const mediaQueryList = window.matchMedia("(max-width: 768px)");
        if (mediaQueryList.matches) {
          if (this.width == 1) {
            this.width = 2;
            this.handleType();
          }
        } else {
          if (this.width == 2) {
            this.width = 1;
            this.handleType();
          }
        }
      }
    },

    currentIndexPrev() {
      this.$refs.swiperRef.prev();
    },
    currentIndexNext() {
      this.$refs.swiperRef.next();
    },
    handleRamps() {
      // window.location.href = "https://example.com";
    },
    handlePDF(url) {
      if (url) window.open(url);
    },
    handleContact() {
      if (this.width == 1) this.dialogVisible = true;
      else this.dialogVisibleH5 = true;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.payzilla {
  font-family: "font1", sans-serif;
  -webkit-text-size-adjust: 100% !important; /* 或者使用 'none' */
}

.swiper-wrapper {
  transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！
}

@import "../css/public.scss";

.color-051513 {
  color: #051513;
}

.color-00A965 {
  color: #00a965;
}
.bottom {
  background-color: #051513;
  color: #fff;
  padding: 50px 0 100px 0;
  .top-bottom {
    background-color: #00d37e;
    width: 100%;
    height: 3px;
  }

  .bottom-btm {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .btm-top {
      width: 1280px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      // font-weight: 500;

      .btm-top-left {
        width: 165px;
        height: 32px;
      }

      .right-content {
        font-size: 18px;
        cursor: pointer;

        .content-flex div:hover {
          color: #00d37e;
        }

        .right-content-icon {
          display: flex;

          img {
            width: 18px;
            height: 18px;
            margin-right: 15px;
          }
        }

        div {
          margin-bottom: 20px;
          // font-weight: 700;;
          font-family: "font2", sans-serif !important;
        }

        & > :nth-child(1) {
          color: #adadad !important;
          font-size: 12px !important;
          margin-bottom: 25px !important;
        }
      }
    }
  }

  .fw-400 {
    width: 100%;
    margin: auto;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;

    span {
      display: inline-block;
      width: 600px;
    }
  }

  .btm-font-text {
    width: 920px;
    margin: auto;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    opacity: 0.7;
    text-align: center;
    font-family: "font1-Light";
  }

  .mt-200 {
    margin-top: 80px;
  }

  .mt-25 {
    margin-top: 25px;
  }
}

@media screen and (max-device-width: 768px) {
  .bottom {
    background-color: #051513;
    color: #fff;
    padding: 200px 0 400px 0;
    .top-bottom {
      background-color: #00a965;
      width: 100%;
      height: 14px;
    }

    .bottom-btm {
      display: flex;
      justify-content: center;
      margin-top: 500px;

      .btm-top {
        width: 80%;
        display: flex;
        flex-direction: column;

        color: #fff;
        // font-weight: 500;

        .h5-none {
          display: none;
        }

        .btm-top-left {
          width: 550px;
          height: 120px;
        }

        .right-content {
          margin-top: 200px;
          font-size: 55px;

          .content-flex {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 1200px;
            font-size: 80px !important;
            margin-bottom: 0 !important;
          }

          .right-content-icon {
            display: flex;

            img {
              width: 90px;
              height: 90px;
              margin-right: 100px;
            }
          }

          div {
            margin-bottom: 70px;
          }

          & > :nth-child(1) {
            color: #adadad !important;
            font-size: 65px !important;
            padding-bottom: 100px !important;
          }
        }
      }
    }

    .fw-400 {
      font-size: 90px;
      // font-weight: 400;;
      line-height: 120px;
      text-align: center;
      font-family: "font1-Light";

      span {
        display: inline-block;
        width: 83%;
      }
    }

    .btm-font-text {
      width: 83%;
      margin: auto;
      // font-weight: 400;;
      font-size: 65px;
      line-height: 100px;
      text-align: center;
      font-family: "font1-Light" !important;
    }

    .mt-200 {
      margin-top: 400px;
    }

    .mt-25 {
      margin-top: 200px;
    }

    .mt-100 {
      margin-top: 100px;
    }
  }

  .bottom-dialog {
    width: 75vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 100px 80px;
    color: #051513;
    z-index: 9999;

    .dialog-top {
      display: flex;
      justify-content: space-between;
      font-size: 70px;
      // font-weight: 400;;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-center {
      font-size: 70px;
      // font-weight: 400;;
      margin: 150px 0;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-bottom-btn {
      display: flex;
      flex-direction: row-reverse;

      div {
        width: 400px;
        font-size: 85px;
        // font-weight: 700;;
        text-align: center;
        border-radius: 15px;
        color: #fff;
        padding: 30px 50px;
        background-color: #00a965;
      }
    }
  }
}

::v-deep .dialog-web .el-dialog__title {
  color: #051513;
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
}

::v-deep .dialog-web .el-dialog__headerbtn .el-dialog__close {
  color: #051513;
  /* 修改关闭图标的颜色 */
}

.dialog-btn {
  width: 116px;
  height: 48px;
  border-radius: 5px;
  padding: 0;
  background-color: #00a965;
  border-color: #00a965;
  font-size: 18px;
  // font-weight: 700;;
  font-family: "font2";
}

.dialog-text {
  // font-weight: 400;;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  font-family: "font1-Light";
  color: #051513;
}
</style>
  