import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const moduleRoutes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    component: () => import('@/views/index/index'),
    name: 'index',
    meta: {
      title: '首页',
      requireAuth: true,
    },
  },
  {
    path: '/about',
    component: () => import('@/views/about/index'),
    name: 'about',
    meta: {
      title: 'about',
      requireAuth: true,
    },
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: moduleRoutes,
})

export default router
