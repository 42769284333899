<template>
  <div id="app">
    <headerNav></headerNav>
    <router-view />
    <bottomNav></bottomNav>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {},

  data() {
    return {
      linkPath: "",
      showHeaderNav: false,
    };
  },
  watch: {

  },
  mounted() {
  
  },
  methods: {
  },
  created() { },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /*list-style: none;*/
  text-decoration: none;
  /*font-family: "Verdana, Geneva, Arial, Helvetica, sans-serif";*/
}

#app {
  position: relative;
  top: 0;
}

html,
body {
  background: #fff;
  overflow-x: hidden;
  /* font-family: "fontFace"; */
}

img {
  width: 100%;
  height: 100%;
  display: block;
}
@font-face {
  font-family: "font1";
  src: url("./font/Hind_Siliguri/HindSiliguri-Regular.ttf");
  /* src: url("./font/Hind_Siliguri/HindSiliguri-Medium.ttf"); */
}

@font-face {
  font-family: "font1-Light";
  src: url("./font/Hind_Siliguri/HindSiliguri-Light.ttf");
}

@font-face {
  font-family: "font2";
  src: url("./font/source-sans-pro/SourceSansPro-Bold.otf");
}

@font-face {
  font-family: "font2-Regular";
  src: url("./font/source-sans-pro/SourceSansPro-Regular.otf");
}
@font-face {
  font-family: "font2-Light";
  src: url("./font/source-sans-pro/SourceSansPro-Light.otf");
}
@font-face {
  font-family: "font2-Semibold";
  src: url("./font/source-sans-pro/SourceSansPro-Semibold.otf");
}

</style>
