/*
 *注册公共组件
 */
import headerNav from '@/components/header-nav'
import bottomNav from '@/components/bottom-nav'

// 整个页面
export default Vue => {
  Vue.component('headerNav', headerNav)  //nav
  Vue.component('bottomNav', bottomNav)  //nav
};

