<template>
  <div class="payzilla">
    <div class="header flex-x flex-btn relative font-medium">
      <div class="left flex-x flex-alc">
        <div class="logo" @click="handleIndex">
          <img alt="" src="../assets/image/logo.png" />
        </div>
        <div class="header_title header_first header_lineHeight hover-Products">
          Products
        </div>
        <div class="hover-Products-card">
          <div class="card-flex">
            <!-- <div class="card-box">
              <div class="color-adadad">Fiat Currency</div>
              <div @click="scrollToPosition">USD</div>
              <div @click="scrollToPosition">EUR</div>
            </div> -->
            <div class="card-box">
              <div class="color-adadad">Crypto</div>
              <div @click="scrollToPosition">Buy/sell USDT</div>
              <div @click="scrollToPosition">Buy/sell USDC</div>
            </div>
            <div class="card-box">
              <div class="color-adadad">Trade</div>
              <div>On & Off Ramps</div>
            </div>
          </div>
        </div>
        <div
          class="header_title ml-100 header_lineHeight"
          @click="handleAccounts"
        >
          Accounts
        </div>
        <div class="header_title ml-100 header_lineHeight" @click="handleAbout">
          About
        </div>
        <div
          class="header_title ml-100 header_lineHeight"
          @click="handlePartners"
        >
          Partners
        </div>
        <div class="header_title ml-100 header_lineHeight">Blog</div>
        <div class="header_title ml-100 header_lineHeight hover-Support">
          Support
        </div>
        <div class="hover-Support-card">
          <div class="card-flex">
            <div class="card-box">
              <div class="color-adadad">Contact us</div>
              <div @click="handleContact">Contact us</div>
            </div>
          </div>
        </div>
        <div class="header-login" @click="handleLogin">Log in</div>
        <div class="header-signup" @click="handleSignup">Sign up</div>
        <div></div>
      </div>
      <div class="right header_title mr-100 header_hover relative">
        <div class="flex-x flex-alc header_lineHeight"></div>
      </div>
      <div
        class="menu header_hover relative"
        @click="showMenu = true"
        v-if="!showMenu"
      >
        <img alt="" src="../assets/image/icon.png" />
      </div>
      <div
        class="menu header_hover relative"
        @click="showMenu = false"
        v-if="showMenu"
      >
        <img alt="" src="../assets/image/icon.png" />
      </div>
      <div class="absolute ul1 ulMenu bg-url" v-show="showMenu">
        <div @click="showIcon = !showIcon" class="li_hover li_hover-image">
          <span>Products</span>
          <img
            v-if="showIcon"
            @click="showIcon = false"
            alt=""
            src="../assets/image/icon1.png"
          />
          <img
            v-else
            alt=""
            @click="showIcon = true"
            src="../assets/image/icon2.png"
          />
        </div>
        <div v-show="!showIcon" class="display">
          <!-- <div class="display-box">
            <div class="display-title">Fiat Currency</div>
            <div class="display-text" @click="handleH5">USD</div>
            <div class="display-text" @click="handleH5">EUR</div>
          </div> -->
          <div class="display-box">
            <div class="display-title">Crypto</div>
            <div class="display-text" @click="handleH5">Buy/sell USDT</div>
            <div class="display-text" @click="handleH5">Buy/sell USDC</div>
          </div>
          <div class="display-box">
            <div class="display-title">Trade</div>
            <div class="display-text">On & Off Ramps</div>
          </div>
          <div class="text-hr"></div>
        </div>
        <div class="mt-28 li_hover" @click="handleAccountsH5">Accounts</div>
        <div class="mt-28 li_hover" @click="handleAbout">About</div>
        <div class="mt-28 li_hover" @click="handlePartnersH5">Partners</div>
        <div class="mt-28 li_hover">Blog</div>
        <!-- <div class="mt-28 li_hover">Support</div> -->
        <div
          @click="showSupportIcon = !showSupportIcon"
          class="li_hover li_hover-image"
        >
          <span>Support</span>
          <img
            v-if="showSupportIcon"
            @click="showSupportIcon = false"
            alt=""
            src="../assets/image/icon1.png"
          />
          <img
            v-else
            alt=""
            @click="showSupportIcon = true"
            src="../assets/image/icon2.png"
          />
        </div>
        <div v-show="!showSupportIcon" class="display">
          <div class="display-box">
            <div class="display-title">Contact us</div>
            <div class="display-text" @click="handleContact(2)">Contact us</div>
          </div>
          <!-- <div class="text-hr"></div> -->
        </div>
      </div>
    </div>
    <el-dialog
      class="dialog-web"
      title="Tips"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span class="dialog-text"
        >drop us an enquiry email to: support@payzilla.io</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          class="dialog-btn"
          type="primary"
          @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹框 -->
    <div
      v-if="dialogVisibleH5"
      :class="dialogType == 2 ? 'bottom-dialog-bg' : ''"
      class="bottom-dialog"
    >
      <div class="dialog-top">
        <div>Tips</div>
        <div @click="dialogVisibleH5 = false">X</div>
      </div>
      <div class="dialog-center">
        drop us an enquiry email to: support@payzilla.io
      </div>
      <div class="dialog-bottom-btn">
        <div @click="dialogVisibleH5 = false" class="btn">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerNav",
  data() {
    return {
      logoShow: true,
      activeIndex: "1",
      style: {},
      opacity: 0,
      showMenu: false,
      showIcon: true,
      showSupportIcon: true,
      width: 1,
      dialogVisible: false,
      dialogVisibleH5: false,
      dialogType: 1,
    };
  },
  created() {},
  mounted() {
    //获取页面宽度
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  methods: {
    handleAbout() {
      if (this.$router.path != "/about") {
        this.handleType();
        this.$router.push({
          path: "/about",
        });
      }
    },

    handleIndex() {
      if (this.$router.path != "/") {
        this.$router.push({
          path: "/",
        });
      }
    },
    handleH5() {
      this.handleType();
      if (this.$router.path != "/index") {
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".content");
          scrollPosition = scrollPosition - 80;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".content");
        scrollPosition = scrollPosition - 80;
        this.handleScrollTo(scrollPosition);
      }
    },
    handleAccountsH5() {
      this.handleType();
      if (this.$router.path != "/index") {
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".card");
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".card");
        this.handleScrollTo(scrollPosition);
      }
    },
    handlePartnersH5() {
      this.handleType();
      if (this.$router.path != "/index") {
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".swiper-bg");
          scrollPosition = scrollPosition - 120;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".swiper-bg");
        scrollPosition = scrollPosition - 120;
        this.handleScrollTo(scrollPosition);
      }
    },
    handlePartners() {
      if (this.$router.path != "/index") {
        this.handleType();
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".swiper-bg");
          scrollPosition = scrollPosition - 120;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".swiper-bg");
        scrollPosition = scrollPosition - 120;
        this.handleScrollTo(scrollPosition);
      }
    },
    handleAccounts() {
      if (this.$router.path != "/index") {
        this.handleType();
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".card");
          scrollPosition = scrollPosition + 80;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".card");
        scrollPosition = scrollPosition + 80;
        this.handleScrollTo(scrollPosition);
      }
    },
    //滚动到指定位置
    scrollToPosition() {
      if (this.$router.path != "/index") {
        this.handleType();
        this.$router.push({
          path: "/",
        });
        this.$nextTick(() => {
          let scrollPosition = this.calculateScrollPosition(".content");
          scrollPosition = scrollPosition - 80;
          this.handleScrollTo(scrollPosition);
        });
      } else {
        let scrollPosition = this.calculateScrollPosition(".content");
        scrollPosition = scrollPosition - 80;
        this.handleScrollTo(scrollPosition);
      }
    },
    // 页面滚动
    handleScrollTo(position) {
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    },
    // 动态计算滚动位置
    calculateScrollPosition(className) {
      let contentElement = document.querySelector(className);
      let contentTop =
        contentElement.getBoundingClientRect().top + window.pageYOffset;
      // 根据屏幕宽度调整滚动位置
      let scrollPosition = contentTop;
      return scrollPosition;
    },
    //监听页面宽度变化
    checkMediaQuery() {
      if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
        this.handleType();
        this.width = 2;
      } else {
        const mediaQueryList = window.matchMedia("(max-width: 768px)");
        if (mediaQueryList.matches) {
          if (this.width == 1) {
            this.width = 2;
            this.handleType();
          }
        } else {
          if (this.width == 2) {
            this.width = 1;
            this.handleType();
          }
        }
      }
    },
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.opacity =
        Math.abs(Math.round(scrollTop)) / 250 > 0.8
          ? 0.8
          : Math.abs(Math.round(scrollTop)) / 250;
      this.style = { background: `rgba(2,21,19,${this.opacity})` };
    },
    handleContact(type = 1) {
      if (this.width == 1) this.dialogVisible = true;
      else {
        this.dialogType = type;
        this.handleType();
        this.dialogVisibleH5 = true;
      }
    },
    handleType() {
      this.showIcon = true;
      this.showMenu = false;
      this.showSupportIcon = true;
    },
	handleLogin(){
		window.open('http://otctrade.payzilla.io','_bank')
	},
	handleSignup(){
		window.open('http://otctrade.payzilla.io/#/register','_bank')
	},
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
  },
  
 
};
</script>
<style lang="scss" scoped>
@import "../css/public.scss";

* {
  list-style: none;
}

.payzilla {
  font-family: "font2";
  -webkit-text-size-adjust: 100% !important; /* 或者使用 'none' */
}

.logo {
  width: 180px;
  height: 35px;
  margin-left: 190px;
  //margin-top: 48px;
}

.ml-100 {
  margin-left: 55px;
}

.header-login {
  width: 116px;
  height: 48px;
  background-color: #051513;
  color: #00a965;
  border: 2px solid #00a965;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  margin-left: 150px;
  // font-weight: 700;;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  /* 添加过渡效果 */
}

.header-login:hover {
  transform: translateY(-5px);
  /* 鼠标悬浮时向上移动10px */
}

.hover-Products:hover + .hover-Products-card {
  display: inline-block;
}

.hover-Products-card:hover {
  display: inline-block;
}

.hover-Products-card {
  display: none;
  position: absolute;
  top: 90px;
  left: 28.8%;
  background: #051513;
  color: #fff;
  box-shadow: 0px 0px 2px 2px #00a965;
  font-size: 16px;

  .card-flex {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    cursor: pointer;

    .card-box {
      width: 200px;
      text-align: left;

      div {
        margin-bottom: 10px;
      }

      .color-adadad {
        color: #adadad;
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
  }
}

.hover-Support:hover + .hover-Support-card {
  display: inline-block;
}

.hover-Support-card:hover {
  display: inline-block;
}

.hover-Support-card {
  display: none;
  position: absolute;
  top: 90px;
  left: 58.7%;
  background: #051513;
  color: #fff;
  box-shadow: 0px 0px 2px 2px #00a965;
  font-size: 16px;

  .card-flex {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    cursor: pointer;

    .card-box {
      width: 200px;
      text-align: left;

      div {
        margin-bottom: 10px;
      }

      .color-adadad {
        color: #adadad;
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
  }
}

.header-signup {
  width: 116px;
  height: 48px;
  background-color: #00a965;
  border: 2px solid #00a965;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  margin-left: 15px;
  // font-weight: 700;;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.header-signup:hover {
  transform: translateY(-5px);
  /* 鼠标悬浮时向上移动10px */
}

.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #051513;

  &_title {
    @include fs(20px, #000, 500);
    cursor: pointer;
    min-width: 35px;
    white-space: nowrap;

    img {
      width: 7px;
      height: 7px;
    }
  }

  ul {
    display: none;
    //height: 230px;
    background: #ffffff;
    box-shadow: 0px 0px 18px rgba(208, 223, 224, 0.85);
    border-radius: 12px;
    opacity: 0.9183;
    text-align: center;
    padding: 22px 26px;
    box-sizing: border-box;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  &_first {
    margin-left: 176px;
  }

  &_hover:hover .ul1 {
    display: block;
    animation: animated 0.5s ease;
  }

  &_hover:hover .ul2 {
    display: block;
    animation: animated1 0.5s ease;
    height: 115px;
  }

  &_hover:hover img {
    transform: rotate(180deg);
  }

  @keyframes rotateTr {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes animated {
    0% {
      height: 0;
      opacity: 0;
    }

    100% {
      //height: 230px;
      opacity: 0.9183;
    }
  }

  @keyframes animated1 {
    0% {
      height: 0;
      opacity: 0;
    }

    100% {
      height: 115px;
      opacity: 0.9183;
    }
  }

  .right {
    &_switch {
      @include fs(18px, #000, 500);
      cursor: pointer;
    }

    &_line {
      width: 1px;
      height: 25px;
      background: #ffffff;
    }

    &_last {
      margin-right: 162px;
    }
  }
}

.header_lineHeight {
  line-height: 90px;
  color: #fff;
  font-size: 18px;
  // font-weight: 700;;
}

.menu {
  display: none;
}

@media screen and (max-device-width: 768px) {
  .header {
    height: 300px !important;
    align-items: center;

    .logo {
      width: 550px !important;
      height: 120px !important;
    }
  }

  .bg-url {
    background-image: url("../assets/image/image4.png") !important;
    background-size: 100% 1200px !important;
    background-position: 0px 0px !important;
    background-repeat: no-repeat !important;
  }

  .header_lineHeight {
    color: #fff;
    font-size: 36px;
    display: none !important;
  }

  .header-login {
    display: none;
  }

  .header-signup {
    display: none;
  }

  // .logo {
  //   width: 340px;
  //   height: 70px;
  //   margin-top: 40px;
  //   margin-left: 100px;
  // }

  .menu {
    display: block;
    width: 180px;
    height: 180px;
    margin-top: 40px;
    margin-right: 100px;

    img {
      width: 150px;
      height: 150px;
    }
  }

  .display {
    :nth-child(1) {
      margin-top: 100px;
    }
  }

  .display-box {
    margin-bottom: 180px;

    .display-title {
      font-size: 60px;
      line-height: 100px;
      color: #adadad;
    }

    .display-text {
      font-size: 70px;
      line-height: 140px;
      color: #00a965;
    }
  }

  .text-hr {
    width: 90%;
    margin: auto;
    height: 5px;
    background-color: #adadad;
    margin-bottom: 180px;
  }

  .ulMenu {
    position: absolute;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px 0px 29.8365px #f0f0f0;
    border-radius: 30px;
    top: 300px;
    padding: 60px 100px;
    text-align: left;
    color: #00a965;

    .li_hover {
      height: 160px;
      line-height: 160px;
      font-size: 70px;
      box-sizing: border-box;
    }

    .li_hover-image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 70px;
        height: 90px;
      }
    }
  }

  .ulMenu {
    animation: animatedMenu 0.5s ease;
  }

  .bottom-dialog-bg {
    background-color: #051513 !important;
    color: #fff !important;
  }

  .bottom-dialog {
    width: 75vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 100px 80px;
    color: #051513;
    z-index: 9999;

    .dialog-top {
      display: flex;
      justify-content: space-between;
      font-size: 70px;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-center {
      font-size: 70px;
      margin: 150px 0;
      font-family: "font1-Light" !important;
      opacity: 0.7;
    }

    .dialog-bottom-btn {
      display: flex;
      flex-direction: row-reverse;

      div {
        width: 400px;
        font-size: 85px;
        text-align: center;
        border-radius: 15px;
        color: #fff;
        padding: 30px 50px;
        background-color: #00a965;
      }
    }
  }

  @keyframes animatedMenu {
    0% {
      height: 0;
      opacity: 0;
    }

    100% {
      height: 800px;
      opacity: 0.9183;
    }
  }
}

::v-deep .dialog-web .el-dialog__title {
  color: #051513;
  font-size: 18px;
  line-height: 18px;
}

::v-deep .dialog-web .el-dialog__headerbtn .el-dialog__close {
  color: #051513;
  /* 修改关闭图标的颜色 */
}

.dialog-btn {
  width: 116px;
  height: 48px;
  border-radius: 5px;
  padding: 0;
  background-color: #00a965;
  border-color: #00a965;
  font-size: 18px;
  font-family: "font2" !important;
}

.dialog-text {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  font-family: "font1-Light";
  color: #051513;
}
</style>
