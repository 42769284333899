//中英
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '../language/zh'
import en from '../language/en'
import tw from './tw'
import Element from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)
Vue.use(Element)
export const messages={
    'zh': {
        _lang: '简体中文',
        ...zh,
        ...zhLocale
    },
    'en': {
        _lang: 'English',
        ...en,
        ...enLocale
    },
    'tc': {
        _lang: '繁体中文',
        ...tw,
        ...twLocale
    },
}
const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'tc',
    messages
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n
