var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payzilla"},[_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"bottom-btm"},[_c('div',{staticClass:"btm-top"},[_vm._m(0),(_vm.width == 2)?_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"h5-none"},[_vm._v("Socials")]),_c('div',{staticClass:"right-content-icon"},[_c('img',{attrs:{"src":require("../assets/image/icon7.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/image/icon8.png"),"alt":""}}),(_vm.width == 1)?_c('img',{attrs:{"src":require("../assets/image/icon9.png"),"alt":""}}):_c('img',{attrs:{"src":require("../assets/image/icon16.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/image/icon10.png"),"alt":""}})])]):_vm._e(),_c('div',{staticClass:"right-content"},[_c('div',[_vm._v("Products")]),_c('div',{staticClass:"content-flex"},[_c('div',{on:{"click":_vm.scrollToPosition}},[_vm._v("Buy/sell USDC")]),_c('div',{on:{"click":_vm.scrollToPosition}},[_vm._v("Buy/sell USDT")]),_c('div',{on:{"click":_vm.handleAccounts}},[_vm._v("Peronal Account")]),_c('div',{on:{"click":_vm.handleAccounts}},[_vm._v("Business Account")])])]),_c('div',{staticClass:"right-content"},[_c('div',[_vm._v("Company")]),_c('div',{staticClass:"content-flex"},[_c('div',{on:{"click":_vm.handleAbout}},[_vm._v("About")]),_c('div',{on:{"click":function($event){return _vm.handlePDF(
                  'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Terms-of-service_latest.pdf'
                )}}},[_vm._v(" Terms of service ")]),_c('div',{on:{"click":function($event){return _vm.handlePDF(
                  'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Terms-of-service_latest.pdf'
                )}}},[_vm._v(" Privacy Statement ")]),_c('div',{on:{"click":function($event){return _vm.handlePDF(
                  'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-Cookie-Policy_latest.pdf'
                )}}},[_vm._v(" Cookie Policy ")]),_c('div',{on:{"click":function($event){return _vm.handlePDF(
                  'https://otc.payzilla.io/wp-content/uploads/doc/Payzilla-AML_KYC-Policy_latest.pdf'
                )}}},[_vm._v(" AML/KYC Policy ")])])]),_c('div',{staticClass:"right-content"},[_c('div',[_vm._v("Support")]),_c('div',{staticClass:"content-flex"},[_c('div',{staticClass:"h5-none"},[_vm._v("Log in")]),_c('div',{staticClass:"h5-none"},[_vm._v("Sign up")]),_c('div',{on:{"click":_vm.handleContact}},[_vm._v("Contact us")]),_c('div',{on:{"click":_vm.handleRamps}},[_vm._v("On & Off Ramps")])])]),(_vm.width == 1)?_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"h5-none"},[_vm._v("Socials")]),_vm._m(1)]):_vm._e()])]),_vm._m(2),_c('div',{staticClass:"btm-font-text mt-25"},[_vm._v(" Registered business address: Alvierweg 17, 9490 Vaduz, Liechtenstein ")]),_c('div',{staticClass:"btm-font-text mt-100 mt-25"},[_vm._v(" Copyright © 2024 Payzilla International AG ")])]),_c('el-dialog',{staticClass:"dialog-web",attrs:{"title":"Tips","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticClass:"dialog-text"},[_vm._v("drop us an enquiry email to: support@payzilla.io")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"dialog-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("Confirm")])],1)]),(_vm.dialogVisibleH5)?_c('div',{staticClass:"bottom-dialog"},[_c('div',{staticClass:"dialog-top"},[_c('div',[_vm._v("Tips")]),_c('div',{on:{"click":function($event){_vm.dialogVisibleH5 = false}}},[_vm._v("X")])]),_c('div',{staticClass:"dialog-center"},[_vm._v(" drop us an enquiry email to: support@payzilla.io ")]),_c('div',{staticClass:"dialog-bottom-btn"},[_c('div',{staticClass:"btn",on:{"click":function($event){_vm.dialogVisibleH5 = false}}},[_vm._v("Confirm")])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btm-top-left"},[_c('img',{attrs:{"src":require("../assets/image/logo.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-content-icon"},[_c('img',{attrs:{"src":require("../assets/image/icon7.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/image/icon8.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/image/icon9.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/image/icon10.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fw-400 mt-200"},[_c('span',[_vm._v(" Payzilla is the brand name of the crypto exchange gateway owned by Liechtenstein-based Payzilla International AG and provides on and off ramping service to individual customers, merchant customers and web3 platforms. Payzilla’s service is registered as TT Exchange Service Provider under TVTG by the FMA Liechtenstein with Registration no. 342210. ")])])}]

export { render, staticRenderFns }